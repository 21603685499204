<template>
  <b-card>
    <g-form>
      <b-row>
        <!-- from date  -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.fromDate"
            label-text="fromDate"
          />
        </b-col>

        <!-- to date  -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.toDate"
            label-text="toDate"
          />
        </b-col>

        <!-- student  -->
        <b-col md="4">
          <student-autocomplete
            ref="autoComlete"
            name="student"
            :value.sync="selectedItem.studentId"
            url="students/getStudentsTaxLookup"
            @change:action="(val) => students.push(val)"
          />
        </b-col>

        <!-- stage  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.stageId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="stage"
              field="select"
              name="stage"
              :options="stages"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- row  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.rowId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="row"
              field="select"
              name="row"
              :options="rows"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>

        <!-- classroom -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.classroomId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="class"
              field="select"
              name="classroom"
              :options="classrooms"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- different print choices -->
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="relief-primary"
            @click="print()"
          >
            <feather-icon
              class="mr-50"
              icon="EyeIcon"
            />
            {{ $t("review") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>
<script>
import reportMixin from '@/mixin/reportMixin';
import { reportParameters } from '@/libs/acl/Lookups'
import StudentAutocomplete from '@/components/StudentAutoComplete.vue';

export default {
  components: {
    StudentAutocomplete,
  },
  mixins: [
    reportMixin,
  ],
  data() {
    return {
      students: [],
      classrooms: [],
      rows: [],
      stages: [],

      selectedItem: {
        fromDate: null,
        toDate: null,
        studentId: null,
        stageId: null,
        rowId: null,
        classroomId: null
      },
    };
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.selectedItem.fromDate = this.getDate(this.fiscalYearStart)
    this.selectedItem.toDate = this.getDate(this.fiscalYearEnd)
    this.getData();
  },
  methods: {
    setDefaultDateIfNotExists() {
      if (this.selectedItem.fromDate === null) {
        this.selectedItem.fromDate = this.getDate(this.fiscalYearStart);
      }
      if (this.selectedItem.toDate === null) {
        this.selectedItem.toDate = this.getDate(this.fiscalYearEnd);
      }
    },

    getData() {
      this.get({ url: 'Classrooms' }).then((data) => {
        this.classrooms = data;
      })
      .then(() => {
        this.get({ url: 'Rows' }).then((data) => {
          this.rows = data;
        })
      })
      .then(() => {
        this.get({ url: 'Stages' }).then((data) => {
          this.stages = data;
        })
      })
    },

    filterDataBaseOnId(source, compareTo) {
      return source.find((val) => val.id === compareTo)
    },

    preparePrametersForPrint() {
      const selectedStudent = this.filterDataBaseOnId(this.students, this.selectedItem.studentId);
      const allStudents = this.filterDataBaseOnId(reportParameters, 'allStudents');
      this.selectedItem.studentName = this.selectedItem.studentId
                                    ? this.isRight ? selectedStudent.arabicName : selectedStudent.englishName
                                    : this.isRight ? allStudents.arabicName : allStudents.englishName;

      const selectedStage = this.filterDataBaseOnId(this.stages, this.selectedItem.stageId);
      const allStages = this.filterDataBaseOnId(reportParameters, 'allStages');
      this.selectedItem.stageName = this.selectedItem.stageId
                                  ? this.isRight ? selectedStage.arabicName : selectedStage.englishName
                                  : this.isRight ? allStages.arabicName : allStages.englishName;

      const selectedRow = this.filterDataBaseOnId(this.rows, this.selectedItem.rowId);
      const allRows = this.filterDataBaseOnId(reportParameters, 'allRows');
      this.selectedItem.rowName = this.selectedItem.rowId
                                ? this.isRight ? selectedRow.arabicName : selectedRow.englishName
                                : this.isRight ? allRows.arabicName : allRows.englishName;

      const selectedClassroom = this.filterDataBaseOnId(this.classrooms, this.selectedItem.classroomId);
      const allClassrooms = this.filterDataBaseOnId(reportParameters, 'allClassrooms');
      this.selectedItem.classroomName = this.selectedItem.classroomId
                                      ? this.isRight ? selectedClassroom.arabicName : selectedClassroom.englishName
                                      : this.isRight ? allClassrooms.arabicName : allClassrooms.englishName;
    },

    print() {
      this.setDefaultDateIfNotExists();
      if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
      this.preparePrametersForPrint();
      this.printReport(this.isRight ? 'StudentsSuccess-ar' : 'StudentsSuccess-en', this.selectedItem);
    },
  },
};
</script>
<style>
</style>
