<template>
  <b-card>
    <g-form>
      <b-row>

        <!-- <b-col md="4">
          <g-picker
            :value.sync="selectedItem.fromDate"
            label-text="fromDate"
          />
        </b-col>

        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.toDate"
            label-text="toDate"
          />
        </b-col> -->
           <!-- student  -->
           <b-col md="4">
          <student-autocomplete ref="autoComlete"
                    name="student"
                    :value.sync="selectedItem.studentId"
                    url="students/getStudentsTaxLookup"
                    @change:action="(val) => this.students.push(val)"
                  />
        </b-col>
      <!-- </b-row>
      <b-row> -->
         <!-- stage  -->
         <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.statusId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="studentStatus"
              field="select"
              name="stage"
              :options="statudenStatueses"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="repots-buttons">
          <b-button style="margin: 5px" variant="relief-primary" @click="print">
            <feather-icon class="mr-50" icon="EyeIcon" />
            {{ $t('review') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>

<script>
import StudentAutocomplete from '@/components/StudentAutoComplete.vue';
import reportMixin from '@/mixin/reportMixin';
import { reportParameters } from '@/libs/acl/Lookups'

export default {
  components: {
    StudentAutocomplete,
  },
  mixins: [reportMixin],
  props: ['id'],
  data() {
    return {
      students: [],
      statudenStatueses: [],
      selectedItem: {
        studentId: null,
        statusId: null,
        fromDate: null,
        toDate: null,
        studentName: null,
        statusName: null,
      },
    };
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.selectedItem.fromDate = this.getDate(this.fiscalYearStart);
    this.selectedItem.toDate = this.getDate(this.fiscalYearEnd);
    this.getData();
  },
  methods: {
    checkDate() {
      if (this.selectedItem.fromDate === null) {
        this.selectedItem.fromDate = this.getDate(this.fiscalYearStart);
      }
      if (this.selectedItem.toDate === null) {
        this.selectedItem.toDate = this.getDate(this.fiscalYearEnd);
      }
    },
    getData() {
        this.get({ url: 'StudentStatuses' }).then((data) => {
          this.statudenStatueses = data;
        })
      },
      filterDataBaseOnId(source, compareTo) {
      return source.find((val) => val.id === compareTo)
      },
      preparePrametersForPrint() {
      const selectedStudent = this.filterDataBaseOnId(this.students, this.selectedItem.studentId);
      const allStudents = this.filterDataBaseOnId(reportParameters, 'allStudents');
      this.selectedItem.studentName = this.selectedItem.studentId
                                    ? this.isRight ? selectedStudent.arabicName : selectedStudent.englishName
                                    : this.isRight ? allStudents.arabicName : allStudents.englishName;

      const selectedStatus = this.filterDataBaseOnId(this.statudenStatueses, this.selectedItem.statusId);
      const allStatuses = this.filterDataBaseOnId(reportParameters, 'allStatuses');
      this.selectedItem.statusName = this.selectedItem.statusId
                                    ? this.isRight ? selectedStatus.arabicName : selectedStatus.englishName
                                    : this.isRight ? allStatuses.arabicName : allStatuses.englishName;
      },
      print() {
      this.checkDate();
      if (!this.checkForFiscalYearPerm(this.selectedItem.fromDate, this.selectedItem.toDate)) return;
      this.preparePrametersForPrint();
      this.printReport(this.isRight ? 'StudentsStatuses-ar' : 'StudentsStatuses-en', this.selectedItem);
    },
  },
};
</script>
<style>
.repots-buttons {
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
}

span.vs__selected {
  background-color: gray;
}

@media (max-width: 768px) {
  .repots-buttons {
    justify-content: center;
  }
}
</style>
