<template>
  <b-row>
    <b-col md="3" class="mt-4">
      <b-card>
        <ul class="list-unstyled">
          <li
            v-bind:class="{ 'btn-flat-primary': selectedReport === x.title }"
            @click="
              () => {
                selectedReport = x.title;
                selected = x;
              }
            "
            class="p-1 nav-item-report cursor-pointer border-bottom"
            v-for="x in reports"
            :key="x.title"
            v-permission="x.permission"
          >
            <feather-icon :icon="x.icon" size="20" class="mr-75" />
            <span>{{ $t(x.title) }}</span>
          </li>
        </ul>
      </b-card>
    </b-col>
    <b-col md="9">
      <b-card-title
        v-if="selectedReport"
        class="p-0 m-0 text-primary text-darken-5"
      >
        <b-row class="p-0">
          <b-col md="12" class="text-center">
            <span>{{ $t(selectedReport) }}</span>
          </b-col>
        </b-row>
      </b-card-title>
      <hr v-if="selectedReport" class="border-info border-darken-2" />
      <studentList v-if="selectedReport === 'students'"/>
      <studentServices v-if="selectedReport === 'studentServies'"/>
      <studentDiscount v-if="selectedReport === 'studentDiscount'"/>
      <collectionVouchers :type="selected" v-if="selectedReport === 'studentsAccountStatement'"/>
      <studentStatuses type="selected" v-if="selectedReport === 'studentStatuses'"/>
      <studentsSuccess type="selected" v-if="selectedReport === 'reportStudentsSuccess'"/>
    </b-col>
  </b-row>
</template>

<script>
import { studentsReports } from '@/libs/acl/Lookups';
import studentList from '@/pages/Settings/Report/studentList.vue';
import studentServices from '@/pages/Settings/Report/studentServices.vue';
import collectionVouchers from '@/pages/Settings/Report/collectionVouchers.vue';
import studentDiscount from '@/pages/Settings/Report/studentDiscounts.vue';
import studentStatuses from '@/pages/Settings/Report/studentStatusReport.vue';
import studentsSuccess from '@/pages/Settings/Report/studentsSuccess.vue';
import reportMixin from '@/mixin/reportMixin';

export default {
  components: {
    studentList,
    studentServices,
    collectionVouchers,
    studentDiscount,
    studentStatuses,
    studentsSuccess
  },
  mixins: [
  reportMixin,
  ],
  data() {
    return {
      menu: studentsReports,
      reports: [],
      selectedReport: '',
      selected: { permission: '' }
    };
  },
  computed: {},
  mounted() {
    this.reports = this.menu;
    this.selectedReport = 'students'
     if (this.profile.permissions.length > 0) {
    this.selectedReport = this.getSelectedReport(this.reports, this.profile.permissions);
    }
  },
  methods: {},
};
</script>

<style scoped>
.nav-item-report:hover {
  margin-left: 5px;
  transition: all 0.5s ease;
}
</style>
